import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../models/index'
import { Error } from '../models/index'
import { AuthService } from '../services/auth.service'
import * as _ from 'lodash';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    signupForm: UntypedFormGroup;
    loadingSignup:boolean = false;
    success:boolean = false;
    submitted:boolean = false;
    resMsg:any;
    res:any;
    user:User;
    userError:User;
    api_error:Error;
    fileData: File = null;
    dataimage:any;
    @ViewChild('fileInput') fileInput: ElementRef;
    fileAttr = 'Choose File';
    imageError: string;
    isImageSaved: boolean;
  constructor(
    private _fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
  ) {
      this.signupForm = _fb.group({
        first_name:['', [Validators.required,Validators.pattern('^[a-zA-Z]+$')]],
        last_name:['', [Validators.required,Validators.pattern('^[a-zA-Z]+$')]],
        phone_number:['', [Validators.required, Validators.minLength(10),Validators.pattern('^(?:254|\\+254|0)(7|1)[0-9]{8}$')]],
        id_number:['',[ Validators.required,Validators.pattern('^[a-zA-Z0-9]*$'),Validators.minLength(7),Validators.maxLength(14)]],
        email:['', [Validators.required,Validators.email]],
        password:['', [Validators.required,Validators.minLength(6)]],
        confirm_password:['', [Validators.required,Validators.minLength(6)]],
        id_image:[''],
        region:['', Validators.required]
    });
  }
  ngOnInit(): void {
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  uploadFileEvt(imgFile: any) {
    this.imageError = null;
    if (imgFile.target.files && imgFile.target.files[0]) {
      // Size Filter Bytes
      const max_size = 2000000;
      const allowed_types = ['image/png', 'image/jpeg','image/jpg'];
      const max_height = 5000;
      const max_width = 5000;
      if (imgFile.target.files[0].size > max_size) {
                this.imageError =
                    'Maximum size allowed is ' + max_size / 1000000 + 'Mb';
                return false;
      }

      if (!_.includes(allowed_types, imgFile.target.files[0].type)) {
               this.imageError = 'Only Images are allowed ( JPG | PNG | JPEG)';
               return false;
           }

      this.fileAttr = '';
      Array.from(imgFile.target.files).forEach((file: File) => {
        this.fileAttr += file.name ;
      });
      // HTML5 FileReader API
      let reader = new FileReader();
      reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          if (img_height > max_height && img_width > max_width) {
              this.imageError =
                  'Maximum dimentions allowed ' +
                  max_height +
                  '*' +
                  max_width +
                  'px';
              return false;
          } else {
            let imgBase64Path = e.target.result;
            this.dataimage = imgBase64Path;
            this.isImageSaved = true;
          }
        };
      };
      reader.readAsDataURL(imgFile.target.files[0]);
      // Reset if duplicate image uploaded again
      this.fileInput.nativeElement.value = "";
    } else {
      this.fileAttr = 'Choose File';
    }
  }

  removeImage() {
        this.dataimage = null;
        this.isImageSaved = false;
    }

   get f() { return this.signupForm.controls; }

   // All is this method
onPasswordChange() {
  if (this.confirm_password.value == this.password.value) {
    this.confirm_password.setErrors(null);
  } else {
    this.confirm_password.setErrors({ mismatch: true });
  }
}

// getting the form control elements
get password(): AbstractControl {
  return this.signupForm.controls['password'];
}

get confirm_password(): AbstractControl {
  return this.signupForm.controls['confirm_password'];
}

   submitSignUp(){
     const formData = new FormData();
    formData.append('file', this.fileData);
    this.submitted = true;
    this.loadingSignup=true;
    // stop here if form is invalid
    if (this.signupForm.invalid ) {
        return;
    }
    if (!this.isImageSaved ) {
        this.imageError = 'Please attach you national ID/Passport image';
        return;
    }

    let value = this.signupForm.value;
    let webformdata={
      "first_name": value.first_name,
      "last_name": value.last_name,
      "email": value.email,
      "password": value.password,
      "id_number": value.id_number,
      "phone_number": '+254' + value.phone_number.substr(value.phone_number.length - 9),
      "region": value.region,
      "assigned_role": "agent",
      "id_image":this.dataimage,
    }
    this.authService.signUp(webformdata).subscribe(
      response => {
        this.loadingSignup=false;
          this.success = true;
          this.resMsg=response;
          this.res= this.resMsg.data.message;
          this.router.navigate(['/success']);
      },
      error => {
          this.success = false;
          this.loadingSignup=false;
          this.userError=new User().deserialize(error.error.errors[0])
          this.user=new User().deserialize(error.error.errors[0])

      }
    );
}

}
