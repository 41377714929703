// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
const userEndpoint='https://v2-user-management.buupass.com/user-management/users/';
const agentsBookings = 'https://marketplace-v2.buupass.com/marketplace/';
export const environment = {
  production: false,
  api_key:'66Shf2aP.owT6xw6QKSWNO9EeQIIlz20JM3nNHul3',
  retrieveEmail: userEndpoint+'retrieve/',
  signUp: userEndpoint+'signup',
  signUpSuperAgent: userEndpoint+'superagent',
  signUpMiniAgent: userEndpoint+'miniagent',
  loginUser:userEndpoint+'login',
  getProfile: userEndpoint+'profile',
  getAgentBookings: agentsBookings + 'booking/retrieve/agent/',
  getALlAgentBookings: agentsBookings + 'booking/retrieve/agents?is_admin=is_admin',
  getAgents: userEndpoint + 'agents',
  getAgentsWithSales:agentsBookings +  'booking/retrieve/agents/users',
  changeStatus: userEndpoint + 'status/',
  changeMiniAgentStatus: userEndpoint + 'miniagent/',
  resetPassword: userEndpoint + 'password-reset/',
  adminGetAgent: userEndpoint +  'profile/',
  superAgentSales:userEndpoint + 'superagent/sales'
};
