import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
//Guest
import { HowitworksComponent } from './howitworks/howitworks.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ContactsComponent} from './contacts/contacts.component';
import { SuccessComponent} from './success/success.component';
import { ResetPassComponent} from './reset-pass/reset-pass.component';
import { SuccessActivateComponent } from './success-activate/success-activate.component';

const routes: Routes =[
  { path: '', component: HomeComponent},
  { path: 'home', component: HomeComponent},
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'how-it-works', component: HowitworksComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'success', component: SuccessComponent },
  { path: 'success/activation', component: SuccessActivateComponent },
  { path: 'reset-password', component: ResetPassComponent },

  {
    path: 'admin',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  }, {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
    }]
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
       // Enable scrolling to anchors
       anchorScrolling: "enabled",
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
