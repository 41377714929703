import { Deserializable } from './deserializable.model';

export class User implements Deserializable {

    public id: string;
    public first_name: string;
    public last_name:string;
    public email: string;
    public password: string;
    public id_number:string;
    public id_image:string;
    public phone_number: string;
    public region: string;
    public token: string;
    public assigned_role:string;
    public created_at: string;
    public is_superuser: boolean;
    public is_suspended: boolean;
    public status: string;

    deserialize(input: any): this{
        Object.assign(this, input);
        return this;
    }

    getFname() {
      return this.first_name ;
    }
    getLname() {
      return this.last_name;
    }
    getFullname(){
      return this.first_name + this.last_name;
    }
    getEmail() {
      return this.email;
    }
    getIdno() {
      return this.id_number;
    }
    getImage() {
      return this.id_image;
    }
    getPhone() {
      return this.phone_number;
    }
    getToken() {
      return this.token;
    }
    getRegion() {
      return this.region;
    }

    getAssignedRole() {
      return this.assigned_role;
    }
    getCreatedAt() {
      return this.created_at;
    }
    getTIsSuperUser() {
      return this.is_superuser;
    }
    getIsSuspended() {
      return this.is_suspended;
    }
    getStatus() {
      return this.status;
    }

    getId() {
      return this.id;
    }



}
