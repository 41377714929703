import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-activate',
  templateUrl: './success-activate.component.html',
  styleUrls: ['./success-activate.component.css']
})
export class SuccessActivateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
