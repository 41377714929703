import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/index'
declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/my-sales', title: 'My Sales',  icon: 'dashboard', class: '' },
    { path: '/user-profile', title: 'My Profile',  icon:'account_box', class: '' },
];

export const SUPERAGENTROUTES: RouteInfo[] = [
    { path: '/super-agent-dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: '/my-sales', title: 'My Sales',  icon: 'style', class: '' },
    { path: '/user-profile', title: 'My Profile',  icon:'account_box', class: '' },
    { path: '/super-agent', title: 'Add Agent',  icon:'person_add', class: '' },
];

export const ADMINROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: '/user-add', title: 'Register Agent/Admin',  icon:'person_add', class: '' },
    { path: '/user-profile', title: 'My Profile',  icon:'account_box', class: '' },
];
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
      user:User;
      menuItems: any[];
      AdminMenuItems: any[];
      SuperAgentMenuItems: any[];
    private savedData:any;

  constructor(private router: Router) {
     let data=localStorage.getItem('agent');
     this.user = JSON.parse(atob(data));
    delete this.user.id_image;
    this.savedData=btoa(JSON.stringify(this.user));

  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.AdminMenuItems = ADMINROUTES.filter(menuItem => menuItem);
    this.SuperAgentMenuItems = SUPERAGENTROUTES.filter(menuItem => menuItem);

  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
