import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/index'
import  { environment } from '../../environments/environment'
const httpOptions = {
  headers: new HttpHeaders({
   'Authorization':  'Api-Key '+ environment.api_key
  })
};
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
      public currentUser: Observable<User>;

      constructor(private http: HttpClient) {
          try {
            this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(atob(localStorage.getItem('agent'))));
            this.currentUser = this.currentUserSubject.asObservable();
          } catch(e) {
            this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUserAbuupass')));
            this.currentUser = this.currentUserSubject.asObservable();
         }
      }

      public get currentUserValue(): User {
          return this.currentUserSubject.value;
      }

      setUserObservable(user){
        this.currentUserSubject.next(user);
      }


        checkEmail(email:string) {
            return this
          .http.get(`${environment.retrieveEmail}`+ email,httpOptions);
        }
          signUp(user:any){
            return this.http.post(environment.signUp,user);
          }
          signUpSuperAgent(token,user:any){
            const userHeader = {
                headers: new HttpHeaders({
                 "Authorization": "Bearer "+ token
                })
              }
            return this.http.post(environment.signUpSuperAgent,user,userHeader);
          }

          signUpMiniAgent(token,user:any){
            const userHeader = {
                headers: new HttpHeaders({
                 "Authorization": "Bearer "+ token
                })
              }
            return this.http.post(environment.signUpMiniAgent,user,userHeader);
          }





          passwordReset(user:any,token){
            const profileOptions = {
                headers: new HttpHeaders({
                 "Authorization": "Bearer "+ token
                })
              }
            return this.http.patch(environment.resetPassword + token,user,profileOptions);
          }
          updateProfile(user:any,token){
            const profileOptions = {
                headers: new HttpHeaders({
                 "Authorization": "Bearer "+ token
                })
              }
            return this.http.patch(environment.getProfile,user,profileOptions);
          }
          login(user:any){
            return this.http.post(`${environment.loginUser}`,user);
          }
          logout() {
              // remove user from local storage to log user out
              localStorage.clear();
              localStorage.removeItem('agent');
              this.currentUserSubject.next(null);
          }
          getProfile(token:any){
            const profileOptions = {
                headers: new HttpHeaders({
                 "Authorization": "Bearer "+ token
                })
              }
            return this
                    .http
                    .get(`${environment.getProfile}`,profileOptions);
          }

}
