import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AppComponent } from './app.component';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { HowitworksComponent } from './howitworks/howitworks.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { HeaderhomeComponent } from './shared/headerhome/headerhome.component';
import { HeadermainComponent } from './shared/headermain/headermain.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ContactsComponent } from './contacts/contacts.component';
import { SuccessComponent } from './success/success.component';
import { AuthInterceptor } from './helpers';
import { ResetPassComponent } from './reset-pass/reset-pass.component';
import { SuccessActivateComponent } from './success-activate/success-activate.component';



@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatProgressBarModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    HowitworksComponent,
    HomeComponent,
    RegisterComponent,
    LoginComponent,
    HeaderhomeComponent,
    HeadermainComponent,
    FooterComponent,
    ContactsComponent,
    SuccessComponent,
    ResetPassComponent,
    SuccessActivateComponent,



  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
