import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { User } from '../models/index'
import { Error } from '../models/index'
import { AuthService } from '../services/auth.service'
import { BehaviorSubject, Observable } from 'rxjs';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    loginsubmitted=false;
    loadingLogin=false;
    successLogin=false;
    resMsg:any;
    user:User;
    api_error:Error;
    api_error_login:any;
    private currentUserSubject: BehaviorSubject<User>;
        public currentUser: Observable<User>;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
    ) {
      this.loginForm = this.formBuilder.group({
          email: ['', [Validators.required,Validators.email]],
          password: ['', Validators.required]
      });

      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();
    }

  ngOnInit() {

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  // convenience getter for easy access to form fields
get f() { return this.loginForm.controls; }

public get currentUserValue(): User {
    return this.currentUserSubject.value;
}

onSubmit() {
  this.loginsubmitted=true;
  this.loadingLogin=true;
  this.api_error=null;
     if (this.loginForm.invalid ) {
        return;
     }
    let value = this.loginForm.value;
    let webformlogin={
      "password": value.password,
      'email':value.email,
    }
    this.authService.login(webformlogin).subscribe(
      response => {
          this.resMsg=response;
          if(this.resMsg.data){
            if(this.resMsg.data.message=='Kindly update your password'){
              let a_token=btoa(this.resMsg.data.data.token);
               localStorage.setItem('reset-token',a_token);
              this.router.navigate(['/reset-password']);
            }else{
              this.getUserProfile(this.resMsg.data.token)
            }

          }
      },
      error => {
          this.successLogin = false;
          this.loginsubmitted=false;
          this.loadingLogin=false;
          this.api_error=new Error().deserialize(error.error.errors)
          this.api_error_login=this.api_error[0].error;
      }
    );
}

getUserProfile(token){
  this.loadingLogin=true;
  this.authService.getProfile(token).subscribe(
    response => {
      this.loadingLogin=false;
      this.loginsubmitted=false;
      this.successLogin = true;
      this.resMsg=response;
        if(this.resMsg.data){
          let a_user=btoa(JSON.stringify(this.resMsg.data));
           this.authService.setUserObservable(this.resMsg.data)
           this.currentUserSubject.next(this.resMsg.data);
           if(this.resMsg.data.assigned_role == 'agent' || this.resMsg.data.assigned_role == 'mini_agent' ){
             localStorage.setItem('isALoggedIn','true');
             localStorage.setItem('agent',a_user);
              this.router.navigate(['/my-sales']);
              //TODO SUPERAGENT ROLE
           }else if(this.resMsg.data.assigned_role == 'super_agent'){
              localStorage.setItem('isALoggedIn','true');
              localStorage.setItem('agent',a_user);
              this.router.navigate(['/super-agent-dashboard']);
           } else if(this.resMsg.data.assigned_role == 'admin'){
             localStorage.setItem('isALoggedIn','true');
             localStorage.setItem('agent',a_user);
              this.router.navigate(['/dashboard']);

           }else{
             this.authService.logout();
             this.router.navigate(['/home']);
           }

        }


    },
    error => {
        this.successLogin = false;
        this.loginsubmitted=false;
        this.loadingLogin=false;
        this.api_error=new Error().deserialize(error.error.errors)
        this.api_error_login=this.api_error[0];
    }
  );

}


}
