import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Error } from '../models/index'
import { AuthService } from '../services/auth.service'

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.css']
})
export class ResetPassComponent implements OnInit {
    resetForm: UntypedFormGroup;
    resetsubmitted=false;
    loadingReset=false;
    resMsg:any;
    api_error:Error
    api_error_reset:any;
    successReset=false;
    resetToken:any;
    user_token:any;
  constructor(
      private formBuilder: UntypedFormBuilder,
      private route: ActivatedRoute,
      private authService: AuthService,
      private router: Router,
  ) {
    this.resetForm = this.formBuilder.group({
      password:['', [Validators.required,Validators.minLength(6)]],
      confirm_password:['', [Validators.required,Validators.minLength(6)]],
    });


    this.resetToken=localStorage.getItem('reset-token');
    try {
      this.user_token = atob(this.resetToken);
      console.log(this.user_token);
    } catch(e) {
      console.log(e);
     this.router.navigate(['/login']);
   }
  }

  ngOnInit(): void {
  }
get f() { return this.resetForm.controls; }
  // All is this method
onPasswordChange() {
 if (this.confirm_password.value == this.password.value) {
   this.confirm_password.setErrors(null);
 } else {
   this.confirm_password.setErrors({ mismatch: true });
 }
}

// getting the form control elements
get password(): AbstractControl {
 return this.resetForm.controls['password'];
}

get confirm_password(): AbstractControl {
 return this.resetForm.controls['confirm_password'];
}

onSubmit() {
  this.resetsubmitted=true;
  this.loadingReset=true;
     if (this.resetForm.invalid ) {
        return;
     }
    let value = this.resetForm.value;
    let webformreset={
      "new_password": value.password
    }
    this.authService.passwordReset(webformreset,this.user_token).subscribe(
      response => {
          this.resMsg=response;
          if(this.resMsg.data){
            if(this.resMsg.status){
              localStorage.clear();
              localStorage.removeItem('reset-token');
              this.router.navigate(['/login']);
            }
            console.log(response);
          }
      },
      error => {
          this.successReset = false;
          this.resetsubmitted=false;
          this.loadingReset=false;
          this.api_error=new Error().deserialize(error.error.errors)
          console.log(this.api_error);
          this.api_error_reset=this.api_error[0];
          this.router.navigate(['/login']);
      }
    );
}

}
